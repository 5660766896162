<template>
  <Layout>
    <div class="main-construction">
      <div class="base-width">
        <Search shopCart btnText='模糊搜索' placeholder="模糊搜索项目" v-ani.fade-up @change="searchChange" />
      </div>
      <div class="c-layout-2">
        <div class="base-width">
          <div class="c-layout-2__lf">
            <!-- 分类 -->
            <Cate :list="cateArr" :curId="getData.cat_id" v-ani.fade-up @change="cateChange" />
          </div>
          <div class="c-layout-2__main" :key="$route.query.id">
            <!-- 广告图 -->
            <Banner class="img" :list="bannerList" height="4.92rem" v-ani.fade-up />
            <!-- 列表 -->
            <div class="md-construction">
              <div class="c-title-2 s22" v-ani.fade-up>项目案例</div>
              <div class="list">
                <template v-for="item in listArr">
                  <CaseItem :value="item" :key="item.engineer_id" v-ani.fade-up />
                </template>
              </div>
            </div>
            <!-- 分页 -->
            <Pagination v-show="total > 0" :page="getData.p" :size="getData.page_num" :total="total" v-ani.fade-up
              @change="changePage" :key="pagKey" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SiteCatListApi, SiteListApi } from "@/request/api/service";
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    let cat_id = this.$route.query.cat_id;
    if (cat_id){
      this.getData.cat_id = cat_id;
    }
    // 分类
    SiteCatListApi().then(({ status, data }) => {
      if (status == 200) {
        this.cateArr = data;
      }
    })
    // 轮播图
    IndexBannerApi({ from: 422 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    this.listFn();
  },
  data() {
    return {
      cateArr: [],
      bannerList: [],  // 轮播图列表
      getData: {
        cat_id: "0",
        p: 1,
        page_num: 12,
        keyword: ""
      },
      listArr: [],
      total: 0,
      pagKey: 0,
      // 案例列表
      list: [
        {
          id: 1, title: '广州天力项目AB栋室内装修工程',
          data1: '占地面积20055m工程总建筑面积146673m',
          data2: '本次精装修工程不包括入户门、户内门、厨柜、衣柜、鞋柜、家政柜、镜柜、浴室柜、护墙板、抽油烟机、炉具、消毒柜等可移动的电器和家具供货及安装，但以达到符合交楼标准要......',
          data3: '广州天河区中山大道天朗明居BRT南侧(骏景花园)',
          imgs: [
            require('@/assets/images/const-1.jpg'),
            require('@/assets/images/const-2.jpg'),
            require('@/assets/images/const-3.jpg'),
          ]
        }, {
          id: 2, title: '越秀大学城和越府(广州国际创新城)2#、10#11#住宅室内装修',
          data1: '占地面积20055m工程总建筑面积146673m',
          data2: '本次广州市益云智慧物流产业孵化基地建设项目公园园林园建绿化工程施工图所包含的园建工程、绿化工程、园林给排水、电气工程等。 承包方式:包工、包料、包工期、包质量、包成活、....',
          data3: '暨南大学番禺校区东侧，南大干线南侧',
          imgs: [
            require('@/assets/images/const-2.jpg'),
            require('@/assets/images/const-1.jpg'),
            require('@/assets/images/const-3.jpg'),
          ]
        }, {
          id: 3, title: '广州天力项目AB栋室内装修工程',
          data1: '占地面积20055m工程总建筑面积146673m',
          data2: '本次精装修工程不包括入户门、户内门、厨柜、衣柜、鞋柜、家政柜、镜柜、浴室柜、护墙板、抽油烟机、炉具、消毒柜等可移动的电器和家具供货及安装，但以达到符合交楼标准要......',
          data3: '广州天河区中山大道天朗明居BRT南侧(骏景花园)',
          imgs: [
            require('@/assets/images/const-3.jpg'),
            require('@/assets/images/const-2.jpg'),
            require('@/assets/images/const-4.jpg'),
          ]
        }, {
          id: 4, title: '越秀大学城和越府(广州国际创新城)2#、10#11#住宅室内装修',
          data1: '占地面积20055m工程总建筑面积146673m',
          data2: '本次广州市益云智慧物流产业孵化基地建设项目公园园林园建绿化工程施工图所包含的园建工程、绿化工程、园林给排水、电气工程等。 承包方式:包工、包料、包工期、包质量、包成活、....',
          data3: '暨南大学番禺校区东侧，南大干线南侧',
          imgs: [
            require('@/assets/images/const-4.jpg'),
            require('@/assets/images/const-1.jpg'),
            require('@/assets/images/const-3.jpg'),
          ]
        }, {
          id: 5, title: '广州天力项目AB栋室内装修工程',
          data1: '占地面积20055m工程总建筑面积146673m',
          data2: '本次精装修工程不包括入户门、户内门、厨柜、衣柜、鞋柜、家政柜、镜柜、浴室柜、护墙板、抽油烟机、炉具、消毒柜等可移动的电器和家具供货及安装，但以达到符合交楼标准要......',
          data3: '广州天河区中山大道天朗明居BRT南侧(骏景花园)',
          imgs: [
            require('@/assets/images/const-5.jpg'),
            require('@/assets/images/const-2.jpg'),
            require('@/assets/images/const-3.jpg'),
          ]
        }
      ]
    };
  },
  methods: {
    searchChange(str) {
      this.getData.p = 1;
      this.getData.keyword = str;
      this.listFn();
    },
    // 分类改变
    cateChange(e) {
      this.getData.cat_id = e;
      this.getData.p = 1;
      this.listFn();
    },
    // 案例列表
    listFn() {
      SiteListApi(this.getData).then(({ status, data }) => {
        if (status == 200) {
          this.listArr = data.list;
          this.total = data.total;
          this.pagKey++;
        }
      })
    },
    // 分页
    changePage(page) {
      this.getData.p = page;
      this.listFn();
      window.scroll({ top: 0, behavior: "smooth", });
    },
  }
};
</script>

<style lang="scss" scoped>
.main-construction {
  padding-bottom: .6rem;
}

.md-construction {
  margin-top: .36rem;

  .list {
    margin-top: .15rem;
  }
}

.md-pagination {
  margin-top: .6rem;
}
</style>