// 服务管理
import request from '@/request/request';


/* 智慧工地 */
// 智慧工地-分类
export const SiteCatListApi = (params) => request.post('/pc/construction.site/cat_list', params);
// 智慧工地-项目案例
export const SiteListApi = (params) => request.post('/pc/construction.site/list', params);
// 智慧工地-项目案例-详情
export const SiteInfoApi = (params) => request.post('/pc/construction.site/info', params);





/* 配套服务 */
// 规范查询
export const QueriesIndexApi = (params) => request.post('/pc/service.Queries/index', params);
// 辅助工具-基础数据
export const QueriesToolBaseApi = (params) => request.post('/pc/service.Queries/tool_base', params);
// 辅助工具-数据列表
export const QueriesToolListApi = (params) => request.post('/pc/service.Queries/tool_list', params);




/* 行业资讯 */
// 分类
export const InformationCatListApi = (params) => request.post('/pc/information.index/cat_list', params);
// 基础数据
export const InformationBaseApi = (params) => request.post('/pc/information.index/base', params);
// 列表
export const InformationListApi = (params) => request.post('/pc/information.index/list', params);
// 详情
export const InformationInfoApi = (params) => request.post('/pc/information.index/info', params);
// 文章评论列表
export const InformationCommentListApi = (params) => request.post('/pc/information.index/comment_article', params);
// 回复资讯
export const InformationReplyArticleApi = (params) => request.post('/pc/information.index/reply_article', params);
